.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  height: calc(100vh - 50px);
}

.leftPane {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px dashed #d7d7d7;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 1.5;
  font-size: calc(5px + 2vmin);
  font-weight: 500;
}

.test-leftPane {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px dashed #d7d7d7;
}

.rightPane {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  border-left: 2px dashed #d7d7d7;
}

.grid-container {
  display: grid;
  position: relative;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
  padding: 10px;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-explanation {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button {
  padding: 15px 30px;
  font-size: 1.2rem;
  color: #ffffff;
  background-color: #8497aa;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s, box-shadow 0.2s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  opacity: 0;
  transition: width 0.3s ease, height 0.3s ease, opacity 0.3s ease;
}

.button:active:after {
  width: 200%;
  height: 200%;
  opacity: 1;
  transition: none;
}

.button:active,
.button.active {
  background-color: #24486e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.button:hover {
  background-color: #24486e;
}

img {
  filter: blur(0) !important;
}

img.mini-image {
  width: calc(25px + 3vw);
  height: calc(25px + 3vh);
}

/* result icon */
.res-grid-container {
  display: grid;
  position: relative;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  /* height: 300px; */
  padding-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  width: 100vh - 40px;
}
.res2-grid-container {
  display: grid;
  position: relative;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
  /* height: 450px; */
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  width: 100vw - 40px;
}
.res-true-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 3px;
  background-color: rgb(117, 201, 117);
}

.res3-true-button {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  height: 30px;
  background-color: rgb(117, 201, 117);
}

.res-leftbtn {
  display: grid;
  place-items: center;
  padding: 3px;
  border-right: 1px solid #000;
}

.res-rightbtn {
  display: grid;
  place-items: center;
  padding: 3px;
}
.res4-grid-container {
  display: grid;
  position: relative;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-rows: repeat(2, 1fr); */
  gap: 10px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  width: 100vw - 40px;
}
.btn-bottom-next {
  position: fixed;
  right: 20px;
  bottom: 30px;
  width: 100px;
  height: 35px;
  cursor: pointer;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0;
  background-color: #252d35;
}
.btn-bottom-next:disabled {
  cursor: not-allowed;
  color: black;
  opacity: 0.6;
  background-color: #939090;
}
.p-game-result {
  padding-top: 15px;
  align-items: center;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
}
.headerbar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bolder;
  background-color: #0000008f;
  z-index: 999;
}

.headerbar p {
  margin: 0;
}

.wrong-click {
  font-weight: 800;
  padding-right: 10px;
  justify-content: center;
  padding-left: 10px;
  color: white;
  align-items: center;
  display: flex;
  position: fixed;
  right: 30px;
  top: 60px;
  width: 50px;
  height: 50px;
  background-color: red;
}
.fixed-height {
  min-height: calc(100vh - 50px);
  overflow: hidden;
}
.ignore_button {
  width: 100px;
  height: 40px;
  cursor: pointer;
  background: red;
  border: none;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.ignore_button.retry_btn {
  background-color: green;
}

.image_arrow {
  max-width: 70px;
  max-height: 70px;
  width: 100%;
}
.arrow_gap {
  height: 15vh;
}
.step4_result_image {
  height: 300px;
}
.step4_result_image img {
  width: 270px;
  height: 100%;
}
.step4_explanation_qus_image img{
  height: 210px;
}

@media only screen and (max-width: 767px) {
  .button-explanation {
    padding: 12px;
  }
  .image_arrow,
  .button-explanation img {
    max-width: 50px;
    max-height: 50px;
    width: 100%;
  }

  img.mini-image {
    max-width: 50px;
    max-height: 50px;
    width: 100%;
  }
  .grid-item.button {
    padding: 12px;
  }
}
@media only screen and (max-width: 992px) {
  .step4_explanation_image {
    padding: 12px;
  }
  .step4_explanation_image img {
    max-width: 50px;
    max-height: 50px;
    width: 100%;
  }
  .button-explanation-auto img {
    max-width: fit-content;
    max-height: fit-content;
    width: 100%;
  }
  .step4_explanation_qus_image img {
    max-height: 98px;
    width: 100%;
    width: auto;
    max-width: 100%;
  }

  .arrow_gap {
    height: 5vh;
  }
  .step4_result_image {
    height: 160px;
  }
  .step4_result_image img {
    width: 160px;
    height: 100%;
  }
}

/* Forhad CSS  */
